import React from 'react';
import { strings } from '../../../messages/messages';
import { titleCase } from '../../../core/utils/helper-functions';
import { MainBanner } from '../../../shared/components/main-banner/main-banner';
import { TextBlock } from '../../../shared/components/text-block/text-block';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { Card } from '../../../shared/components/card/card';
import { Banner } from '../../../shared/components/banner/banner';
import { TextPosition } from '../../../shared/components/banner/style-helpers';
import { CustomMasonry } from '../../../shared/components/custom-masonry/custom-masonry';
import { SplitBanner } from '../../../shared/components/split-banner/split-banner';
import { BlockQuote } from '../../../shared/components/block-quote/block-quote';
import { ContactButton } from '../../../shared/components/contact-button/contact-button';
import MainBannerImage from '../../../img/software-engineering/mainboard2.jpg';
import glasses from '../../../img/software-engineering/glasses.jpg';
import collaboration from '../../../img/software-engineering/collaboration.jpg';
import './software-development.scss';
import { config } from '../../../core/config/config';
import scssStyles from '../../../../Variables.scss';

const npDarkGreenGray = scssStyles['npDarkGreenGray'];
const npGreen = scssStyles['npGreen'];

export class SoftwareDevelopment extends React.PureComponent {

	componentDidMount() {
		this.props.onPageChange('softwareDevelopment');
		const contribution = {
			link: 'http://www.freepik.com',
			text: 'Device mocks designed by rawpixel.com / Freepik'
		};
		this.props.onContributionPush(contribution);
		library.add(faClock);
		library.add(faCogs);
		library.add(faLock);
		library.add(faPuzzlePiece);
		window.scrollTo(0,0);
	}

	createMasonryArray = () => {
		const cardBgColor = '#f7f7f7';
		const masonryArray = [];
		masonryArray.push(
			<Card
				key={'card-clock'}
				bgColor={cardBgColor}
				faCode={'clock'} header={strings.fast}
				text={strings.cardTextFast}
			/>
		);
		masonryArray.push(
			<Card
				key={'card-cogs'}
				bgColor={cardBgColor}
				faCode={'cogs'}
				header={strings.resilient}
				text={strings.cardTextResilient}
			/>
		);
		masonryArray.push(
			<Card
				key={'card-lock'}
				bgColor={cardBgColor}
				faCode={'lock'}
				header={strings.secure}
				text={strings.cardTextSecure}
			/>
		);
		masonryArray.push(
			<Card
				key={'card-puzzle'}
				bgColor={cardBgColor}
				faCode={'puzzle-piece'}
				header={strings.modular}
				text={strings.cardTextModular}
			/>
		);
		return masonryArray;
	};

	render() {
		document.title = config.baseTitle + ' | ' + titleCase(strings.softwareDevelopment);
		const masonry = this.createMasonryArray();
		return (
			<>
				<MainBanner bannerImg={MainBannerImage} title={titleCase(strings.softwareDevelopment)} />
				<div style={{ width: '100%', height: '4em' }}/>
				<div className={'sd__block-quote--wrapper'}>
					<BlockQuote
						quote={
							`... with proper design, the features come cheaply. This approach is arduous, but continues
							to succeed.`
						}
						reference={'Dennis Ritchie'}
					/>
				</div>
				<div className={'sd__text-block'}>
					<TextBlock text={strings.softwareDevDescription}/>
				</div>
				<div className={'sd__card-wrapper'}>
					<CustomMasonry header={strings.devCardsHeader}>
						{ masonry }
					</CustomMasonry>
				</div>
				<SplitBanner header={strings.platformsBannerHeader}/>
				<div style={{ width: '100%', height: '5em' }}/>
				<Banner
					bgColor={npGreen}
					header={strings.agileBannerHeader}
					headerColor={'#ffffff'}
					description={strings.agileBannerText}
					descriptionColor={'#ffffff'}
					image={glasses}
					textPosition={TextPosition.LEFT}
				/>
				<div style={{ width: '100%', height: '5em' }}/>
				<Banner
					bgColor={npDarkGreenGray}
					header={strings.standAloneHeader}
					headerColor={'#ffffff'}
					description={strings.standAloneText}
					descriptionColor={'#ffffff'}
					image={collaboration}
					textPosition={TextPosition.RIGHT}
				/>
				<div className={'sd__contact-button--wrapper'}>
					<ContactButton backgroundColor={npGreen} textColor={'#ffffff'}/>
				</div>
			</>
		);
	}

}

import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';
import './custom-masonry.scss';

function getHeader(header) {
	if (header) {
		return <h4 className={'custom-masonry__header'}>{ header }</h4>
	}
	return null;
}

export const CustomMasonry = ({ header, children }) => {
	return (
		<>
			{ getHeader(header) }
			<Masonry options={{ isFitWidth: true }} className={'custom-masonry__masonry'}>
				{ children }
			</Masonry>
		</>
	);
};

CustomMasonry.propTypes = {
	header: PropTypes.string,
	children: PropTypes.arrayOf(PropTypes.node).isRequired
};
import React from 'react';
import PropTypes from 'prop-types'
import './text-block.scss';

export const TEXT_ALIGN = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right'
};

function getHeaderTextAlign(textAlign) {
	switch (textAlign) {
		case TEXT_ALIGN.LEFT:
			return { textAlign: 'left' };
		case TEXT_ALIGN.CENTER:
			return { textAlign: 'center' };
		case TEXT_ALIGN.RIGHT:
			return { textAlign: 'right' };
		default:
			return { textAlign: 'left' };
	}
}

function getHeader(header, align) {
	if (header) {
		return (
			<h4 style={getHeaderTextAlign(align)}>
				{ header }
			</h4>
		);
	}
	return null;
}

export const TextBlock = props => {
	const header = getHeader(props.header, props.headerAlign);
	return (
		<div className={'text-block--wrapper limit-width'}>
			{ header }
			<p className={'text-block--text'} style={getHeaderTextAlign(props.textAlign)}>
				{ props.text }
			</p>
		</div>
	);
};

TextBlock.propTypes = {
	header: PropTypes.string,
	headerAlign: PropTypes.oneOf([TEXT_ALIGN]),
	text: PropTypes.string.isRequired,
	textAlign: PropTypes.oneOf([TEXT_ALIGN])
};
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './card.scss';

export const Card = props => {
	const bgColor = props.bgColor ? props.bgColor : '#ffffff';
	return (
		<div className={'card--wrapper'} style={{ backgroundColor: bgColor }}>
			<div className={'card__image--wrapper'}>
				<FontAwesomeIcon className={'card__fa-icon'} icon={props.faCode}/>
			</div>
			<div className={'card__text--wrapper'}>
				<div className={'card__text__header'}>
					<h5>{ props.header.toString().toUpperCase() }</h5>
				</div>
				<div className={'card__text__body'}>
					<p>{ props.text }</p>
				</div>
			</div>
		</div>
	);
};

Card.propTypes = {
	bgColor: PropTypes.string,
	faCode: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
};
import React from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import detectBrowserLanguage from 'detect-browser-language';
import { Home } from '../static/home/home';
import { About } from '../static/about/about';
import { SoftwareDevelopment } from '../static/software-development/software-development';
import { WebDesign } from '../static/web-design/web-design';
import { Legal } from '../static/legal/legal';
import { Header } from '../static/_header/header';
import { Footer } from '../static/_footer/footer';
import { strings } from '../../messages/messages';

export class AppRouter extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			selected: 'home',
			language: 'en',
			contribution: null
		};
	}

	componentDidMount() {
		const userLanguage = detectBrowserLanguage();
		if (userLanguage !== undefined) {
			this.setState({
				language: userLanguage.substring(0, 2)
			});
		}
	}

	_handleContributionPush = contribution => {
		this.setState({ contribution });
	};

	_changeActiveLang = lang => {
		strings.setLanguage(lang);
		document.documentElement.lang = lang;
		this.setState({ language: lang });
	};

	_changeSelectedPage = newPage => {
		this.setState({ selected: newPage });
	};

	render() {
		return (
			<Router>
				<Switch>
					{
						['/about', '/software-development', '/web-design', '/legal'].map(path => (
							<Route
								key={path}
								path={path}
								render={props => <Header {...props} selected={this.state.selected} />}
							/>
						))
					}
				</Switch>
				<Switch>
					<Route
						path={'/software-development'}
						render={
							props => <SoftwareDevelopment {...props}
								onPageChange={this._changeSelectedPage}
								onContributionPush={this._handleContributionPush}
							/>
						}
					/>
					<Route
						path={'/web-design'}
						render={
							props => <WebDesign {...props}
								onPageChange={this._changeSelectedPage}
								onContributionPush={this._handleContributionPush}
							/>
						}
					/>
					<Route
						path={'/about'}
						render={
							props => <About {...props}
								onPageChange={this._changeSelectedPage}
								onContributionPush={this._handleContributionPush}
							/>
						}
					/>
					<Route
						path={'/legal'}
						render={
							props => <Legal {...props}
								onPageChange={this._changeSelectedPage}
								onContributionPush={this._handleContributionPush}
							/>
						}
					/>
					<Route
						exact path={'/'}
						render={
							props => <Home {...props}
								onPageChange={this._changeSelectedPage}
								onContributionPush={this._handleContributionPush}
							/>
						}
					/>
					<Route render={() => <Redirect to={'/'} />} />
				</Switch>
				<Footer
					lang={this.state.language}
					onLanguageChange={this._changeActiveLang}
					selected={this.state.selected}
					contribution={this.state.contribution}
				/>
			</Router>
		);
	}
}
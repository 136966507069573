export const globalStrings = {
	en: {
		softwareDevelopment: 'software development',
		webDesign: 'web design',
		about: 'about',
		legal: 'Legal Notice / Privacy Policy'
	},
	de: {
		softwareDevelopment: 'softwareentwicklung',
		webDesign: 'webdesign',
		about: 'über',
		legal: 'Impressum / Datenschutzerklärung'
	}
};
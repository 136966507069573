import React from 'react';
import hypokaimenon from '../../../img/about/hypokaimenon.png';
import { strings } from '../../../messages/messages';
import { TechnologyStack } from '../../../shared/components/technology-stack/technology-stack';
import { TechItem } from '../../../shared/components/technology-stack/tech-item';
import { config } from '../../../core/config/config';
import { titleCase } from '../../../core/utils/helper-functions';
import './about.scss';

function getLangMasonryItems() {
	return [
		new TechItem(require('../../../img/technology-stack/languages/c.png'), 'C Language'),
		new TechItem(require('../../../img/technology-stack/languages/cpp.png'), 'C++ Language'),
		new TechItem(require('../../../img/technology-stack/languages/c_sharp.png'), 'C# Language'),
		new TechItem(require('../../../img/technology-stack/languages/js.png'), 'JavaScript'),
		new TechItem(require('../../../img/technology-stack/languages/python.png'), 'Python', 14),
		new TechItem(require('../../../img/technology-stack/languages/ruby.png'), 'Ruby'),
	];
}

function getFrameworksMasonryItems() {
	return [
		new TechItem(require('../../../img/technology-stack/frameworks/nodejs.png'), 'Node JS'),
		new TechItem(require('../../../img/technology-stack/frameworks/react.png'), 'React'),
		new TechItem(require('../../../img/technology-stack/frameworks/electron.svg'), 'Electron', 29, 4),
		new TechItem(require('../../../img/technology-stack/frameworks/express.png'), 'Express', 18),
		new TechItem(require('../../../img/technology-stack/frameworks/angular.png'), 'Angular'),
		new TechItem(require('../../../img/technology-stack/frameworks/ror.png'), 'Ruby on Rails', 15, 4),
	];
}

function getDatabaseMasonryItems() {
	return [
		new TechItem(require('../../../img/technology-stack/databases/mariadb.png'), 'MariaDB', null, 7),
		new TechItem(require('../../../img/technology-stack/databases/postgres.png'), 'PostgresDB', null, 7)
	];
}

function getProjectManagementMasonryItems() {
	return [
		new TechItem(require('../../../img/technology-stack/project-management/git.png'), 'Git', null, 3),
		new TechItem(require('../../../img/technology-stack/project-management/bitbucket.png'), 'Bitbucket', 25, 3),
		new TechItem(require('../../../img/technology-stack/project-management/jira.png'), 'Jira', 26, 3),
		new TechItem(require('../../../img/technology-stack/project-management/trello.png'), 'Trello', 20, 3)
	];
}

export class About extends React.PureComponent {

	componentDidMount() {
		this.props.onPageChange('about');
		this.props.onContributionPush(null);
		window.scrollTo(0, 0);
	}

	render() {
		document.title = config.baseTitle + ' | ' + titleCase(strings.aboutMe);
		return (
			<div id="about--main" className="limit-width">
				<div className="side-text">
					<div id="cv">
						<h4 id="cv-header">{ strings.aboutMe }</h4>
						<p className="cv-entry">
							<span className="cv-entry-header">05/1991</span><br/>
							<span className="cv-entry-body">world.init(me, wien);</span>
						</p>
						<p className="cv-entry">
							<span className="cv-entry-header">08/2014</span><br/>
							<span className="cv-entry-body">abschluss germanistikstudium</span>
						</p>
						<p className="cv-entry">
							<span className="cv-entry-header">08/2014&ndash;06/2016</span><br/>
							<span className="cv-entry-body">
							tutor für »technische grundlagen und systemsoftware« an der fakultät
							für informatik, univ. wien
						</span>
						</p>
						<p className="cv-entry">
							<span className="cv-entry-header">09/2014&ndash;08/2016</span><br/>
							<span className="cv-entry-body">
							projektmitarbeiter an der fakultät für informatik, univ. wien
						</span>
						</p>
						<p className="cv-entry">
							<span className="cv-entry-header">seit 06/2018</span><br/>
							<span className="cv-entry-body">selbstständiger softwareentwickler und webdesigner</span>
						</p>
					</div>
					<div id="contact">
						<h4>{ strings.contact }</h4>
						<a href="mailto:root@nenad-petkovic.com">root[at]nenad-petkovic[dot]com</a>
					</div>
					<div id="skills">
						<h4 id="skills-header">communication</h4>
						<p className="skills-entry">
							deutsch, english, српскохрватски, 日本語
						</p>
					</div>
				</div>
				<div className="side-image">
					<img src={hypokaimenon} alt="Foto Nenad Petkovic"/>
				</div>
				<div id={"tech-stack"} className={"technology-stack--wrapper"}>
					<h4>technology stack</h4>
					<TechnologyStack stackTitle={"programming languages"} techItems={getLangMasonryItems()}/>
					<TechnologyStack stackTitle={"frameworks"} techItems={getFrameworksMasonryItems()}/>
					<TechnologyStack stackTitle={"databases"} techItems={getDatabaseMasonryItems()}/>
					<TechnologyStack stackTitle={"project management"} techItems={getProjectManagementMasonryItems()}/>
				</div>
			</div>
		);
	}

}

import React from 'react';
import PropTypes from 'prop-types';
import './main-banner.scss';

export const MainBanner = ({ bannerImg, title }) => {
	return (
		<div className='main-banner' style={{ backgroundImage: `url(${bannerImg})`}}>
			<h1 className="main-banner--header">{title}</h1>
		</div>
	);
};

MainBanner.propTypes = {
	bannerImg: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
};
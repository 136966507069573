import React from 'react';
import PropTypes from 'prop-types';
import './block-quote.scss';

export const BlockQuote = ({ quote, reference }) => {
	return (
		<div className={'block-quote--wrapper limit-width'}>
			<div className={'block-quote--bracket block-quote--bracket--left'}>
				{ '{' }
			</div>
			<div className={'block-quote--quote'}>
				<div className={'block-quote--quote__text'}>{ quote }</div>
				<div className={'block-quote--quote__reference'}>— { reference }</div>
			</div>
			<div className={'block-quote--bracket block-quote--bracket--right'}>
				{ '}' }
			</div>
		</div>
	);
};

BlockQuote.propTypes = {
	quote: PropTypes.string.isRequired,
	reference: PropTypes.string.isRequired
};
import React from 'react';
import './language-selector.scss';

function getLangButtons(languages, currentLang, languageChangeCb) {
	const result = [];
	for (let i = 0; i < languages.length; ++i) {
		const language = languages[i];
		if (language === currentLang) {
			result.push(
				<div key={language} className={'lang selectedLang'}>{ language }</div>
			);
		} else {
			result.push(
				<div key={language} className={'lang selectableLang'} onClick={() => languageChangeCb(language)}>
					{ language }
				</div>
			);
		}
		if (i !== languages.length - 1) {
			result.push(<div key={'sep_after_' + language} className='separator'>|</div>);
		}
	}
	return (
		<div id='languages'>
			{ result }
		</div>
	);
}

export const LanguageSelector = ({ lang, onLanguageChange }) => {
	const languages = ['de', 'en'];
	return getLangButtons(languages, lang, onLanguageChange);
};
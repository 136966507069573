const softwareDevDescription = `
Als freier Softwareentwickler biete ich eine umfassende Anzahl an Services und Lösungen
zur Realisierung deiner Produkte an. Ich plane und entwickle webbasierte Anwendungen sowie Applikationen sowohl für
Smartphones als auch für den Desktop und decke dabei unter anderem folgende Bereiche ab: Softwarearchitekturen,
Backend-Programmierung, Datenbankdesign und -implementierung, Frontend-Programmierung, UI-Design und Projektplanung.
`;

const cardTextFast = `Kurze Antwort- und Reaktionszeiten garantieren ein frustfreies Verwenden der Applikation und 
stellen somit einen wichtigen Faktor zur Kund*innengewinnung und -bindung dar.`;

const cardTextResilient = `Softwaresysteme sollen auch bei Auftreten von Fehlern bzw. problematischem User-Input ihren
Dienst weiter verrichten. Hierfür sind saubere Fehlerbehandlungsroutinen und klares State-Handling unabdingbar.`;

const cardTextSecure = `Software muss in Hinblick auf die Sicherheit von User*innendaten gestaltet sein. Die Analyse von
potentiellen Angriffsflächen und Erstellung von Abwehrstrategien stellen somit einen intrinsischen Teil der
Softwareentwicklung dar.`;

const cardTextModular = `Die Segmentierung eines Softwaresystems in lose gekoppelten modularen Strukturen steigert die
Effizienz bei jeglicher Modifikation des Systems, besonders dann wenn zu Beginn noch nicht alle Anforderungen bekannt
sind.`;

const agileBannerText = `
Mithilfe von agiler Softwareentwicklung können Änderungen durch sich verändernde Anforderungen an die und Erwartungen
von der Software mühelos und kostengünstig auch während des Projekts realisiert werden. Ich arbeite nach dem
Scrum-Verfahren, welches unter bestimmten Umständen und je nach Projekttyp durchaus auch modifiziert werden soll.
Verfolge den Fortschritt des Projekts durch kurze Release-Zyklen, rasches Feedback, Burn-Down-Diagramme sowie deine
stete Eingebundenheit in die Entwicklung vom Projektstart an. Profitiere außerdem von meinen transparenten Preisen und
voller Kostenkontrolle, sodass Projektfortschritt und Entwicklung niemals aus dem Fokus geraten.  
`;

const standAloneText = `
Ob du einen Entwickler brauchst, der das Projekt übernimmt, oder du Support für ein bestehendes Team brauchst, ich führe
selbstständig Projekte durch oder übernehme Teilbereiche eines größeren Softwaresystems. 
`;

export const de = {
	/* Core */
	softwareDevDescription,
	/* Cards */
	devCardsHeader: 'Meine Prinzipien der Softwareentwicklung',
	fast: 'schnell',
	resilient: 'robust',
	secure: 'sicher',
	modular: 'modular',
	cardTextFast,
	cardTextResilient,
	cardTextSecure,
	cardTextModular,
	platformsBannerHeader: 'Bereit für alle Plattformen',
	/* Banners */
	agileBannerHeader: 'Agil bleiben – von der Planung bis zum Release',
	agileBannerText,
	standAloneHeader: 'Stand-Alone oder als Support für dein Team',
	standAloneText
};
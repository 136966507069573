import React from 'react';
import PropTypes from 'prop-types';
import { TechItem } from './tech-item';
import './technology-stack.scss';

function getMasonryChildren(techItems) {
	const childrenArr = [];
	for (const item of techItems) {
		childrenArr.push(
			<div className={"masonry__item"} style={{ width: item.width + 'em', height: item.height + 'em' }}>
				<img src={item.image} alt={item.descr}/>
			</div>
		);
	}
	return childrenArr;
}

export const TechnologyStack = ({ stackTitle, techItems }) => {
	const masonryContent = getMasonryChildren(techItems);
	return (
		<>
			<h2 className={"stack__title"}>{ stackTitle }</h2>
			<div className={"stack__masonry"}>
				{ masonryContent }
			</div>
		</>
	);
};

TechnologyStack.propTypes = {
	stackTitle: PropTypes.string.isRequired,
	techItems: PropTypes.arrayOf(TechItem).isRequired
};

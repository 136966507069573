import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './contact-button.scss';

const scssVars = require('../../../../Variables.scss');
const npGreen = scssVars['npGreen'];

library.add(faChevronRight);

export const ContactButton = ({ backgroundColor, textColor }) => {
	return (
		<a className={'contact-button'} href={'mailto:root@nenad-petkovic.com'}>
			<div className={'contact-button__message'} style={{ backgroundColor, color: textColor }}>
				Contact me
			</div>
			<div className={'contact-button__icon'}>
				<FontAwesomeIcon icon={'chevron-right'}/>
			</div>
		</a>
	);
};

ContactButton.propTypes = {
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string
};

ContactButton.defaultProps = {
	backgroundColor: npGreen,
	textColor: '#ffffff'
};
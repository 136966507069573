import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { strings } from '../../../messages/messages';
import { config } from '../../../core/config/config';
import './home.scss';

export class Home extends React.PureComponent {

	componentDidMount() {
		this.props.onPageChange('home');
		this.props.onContributionPush(null);
		window.scrollTo(0, 0);
	}

	render() {
		document.title = config.baseTitle;
		return (
			<div id="home--wrapper" className="limit-width">
				<p id="name-landing">::nenad petković</p>
				<ul id="navigation-landing">
					<li><Link to='/software-development'>{ strings.softwareDevelopmentSnake }()</Link></li>
					<li><Link to='/web-design'>{ strings.webDesignSnake }()</Link></li>
					<li><Link to='/about'>{ strings.about }()</Link></li>
				</ul>
			</div>
		);
	}
}

Home.propTypes = {
	onPageChange: PropTypes.func
};
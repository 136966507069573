import React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { config } from '../../../core/config/config';
import { LanguageSelector } from '../../../shared/components/language-selector/language-selector';
import { strings } from '../../../messages/messages';
import PropTypes from 'prop-types';
import './footer.scss';

const scssVariables = require('../../../../Variables.scss');
const npGreen = scssVariables['npGreen'];
const npRed = scssVariables['npRed'];
const npDefault = scssVariables['active'];

function addSelectedStyle(prop, elem) {
	if (prop === elem) {
		switch (elem) {
			case 'softwareDevelopment':
				return { color: npGreen };
			case 'webDesign':
				return { color: npRed };
			default:
				return { color: npDefault }
		}
	}
	return null;
}

function getContribution(contribution) {
	if (!contribution) {
		return null;
	}
	return (
		<div className="contribution">
			<a href={contribution.link} target="_blank" rel="noopener noreferrer">{contribution.text}</a>
		</div>
	);
}

export const Footer = ({ lang, onLanguageChange, selected, contribution }) => {
	const now = new Date();
	const toYear = now.getFullYear() !== 2018 ? '-' + now.getFullYear() : '';
	const c = getContribution(contribution);
	return (
		<footer id="main-footer" className="limit-width">
			<div className="footer__contribution-wrapper">
				{ c }
			</div>
			<div id="footer">
				<div id="footer-left">
					<div id='name'>
						<a href="mailto:root@nenad-petkovic.com">© 2018{toYear} nenad petković</a>
					</div>
					<div id='language-selector-wrapper'>
						<LanguageSelector lang={lang} onLanguageChange={onLanguageChange}/>
					</div>
				</div>
				<div id="footer-right">
					<MediaQuery maxWidth={config.screenSizes.s}>
						<div className='footer-right__wrapper-left'>
							<div className='footer-right__item footer-right__left-item'>
								<Link
									to='/software-development'
									style={addSelectedStyle(selected, 'softwareDevelopment')}
								>
									{ strings.softwareDevelopment }
								</Link>
							</div>
							<div className='footer-right__item footer-right__right-item'>
								<Link
									to='/web-design'
									style={addSelectedStyle(selected, 'webDesign')}
								>
									{ strings.webDesign }
								</Link>
							</div>
						</div>
						<div className='footer-right__wrapper-right'>
							<div className='footer-right__item footer-right__left-item'>
								<Link
									to='/about'
									style={addSelectedStyle(selected, 'about')}
								>
									{ strings.about }
								</Link>
							</div>
							<div className='footer-right__item footer-right__right-item'>
								<Link
									to='/legal'
									style={addSelectedStyle(selected, 'legal')}
								>
									{ strings.legalFooter }
								</Link>
							</div>
						</div>
					</MediaQuery>
					<MediaQuery minWidth={config.screenSizes.s}>
						<div className='footer-right__item'>
							<Link
								to='/software-development'
								style={addSelectedStyle(selected, 'softwareDevelopment')}
							>
								{ strings.softwareDevelopment }
							</Link>
						</div>
						<div className='footer-right__item'>
							<Link
								to='/web-design'
								style={addSelectedStyle(selected, 'webDesign')}
							>
								{ strings.webDesign }
							</Link>
						</div>
						<div className='footer-right__item'>
							<Link
								to='/about'
								style={addSelectedStyle(selected, 'about')}
							>
								{ strings.about }
							</Link>
						</div>
						<div className='footer-right__item'>
							<Link
								to='/legal'
								style={addSelectedStyle(selected, 'legal')}
							>
								{ strings.legalFooter }
							</Link>
						</div>
					</MediaQuery>
				</div>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	lang: PropTypes.string,
	onLanguageChange: PropTypes.func,
	selected: PropTypes.string,
	contribution: PropTypes.object
};

Footer.defaultProps = {
	contribution: null
}

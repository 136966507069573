export const config = {
	screenSizes: {
		xs: 400,
		s: 550,
		m: 660,
		l: 750,
		xl: 1300
	},
	transitionTime: '0.3s',
	baseTitle: 'Nenad Petkovic Software Development'
};

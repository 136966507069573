const softwareDevDescription = `
As a free software developer I offer a comprehensive amount of services and solutions for realizing your products. I arrange
and develop web-based applications as well as apps for smartphones and desktop environments and cover, inter alia, the
following areas: software architecture, backend development, database design and implementation, frontend development, UI design
and project planning.
`;

const cardTextFast = `A short response time ensures high usability for your application and thus is an important factor
for customer acquisition and retention.`;

const cardTextResilient = `Software systems should continue working even in case of errors or erroneous user input. Therefore,
it is essential to have reliable error-handling routines and a clean state handling.`;

const cardTextSecure = `Software has to be designed with regard to user-data security. Thus, continuous analysis of potential
security flaws and building defensive strategies constitute an intrinsic part of every software development process.`;

const cardTextModular = `Segmenting a software system in loosely coupled modular structures increases efficiency when it comes
to modifying an existing structure. This is especially so if at the beginning of a project not all of the requirements are known.`;

const agileBannerText = `
With the help of agile software development it is possible to realize changes easily and in a an economic manner due to altering
requirements for and expectations towards a software even during an on-going project. I work by the principles of the Scrum process
which can and should be modified depending on the project type. Keep track of your project with short release cycles, swift
feedback Burn-Down-Charts and your continuous integration the development process from the project start. Benefit by my transparent
prices and full cost control so that project progress and the actual development never drift out of focus.
`;

const standAloneText = `
Whether you need a developer that undertakes a whole project or support for your already existing team, I got you covered. I 
independently carry out projects or take on certain areas of a bigger software system.
`;

export const en = {
	/* Core */
	softwareDevDescription,
	/* Cards */
	devCardsHeader: 'My Principles of Software Development',
	fast: 'fast',
	resilient: 'robust',
	secure: 'secure',
	modular: 'modular',
	cardTextFast,
	cardTextResilient,
	cardTextSecure,
	cardTextModular,
	platformsBannerHeader: 'Ready for all Platforms',
	/* Banners */
	agileBannerHeader: 'Stay Agile – from Planning to Deployment',
	agileBannerText,
	standAloneHeader: 'Stand-alone or as Support for your Team',
	standAloneText
};
import LocalizedStrings from 'react-localization';
import { globalStrings } from '../../global-strings';
import { strings as footerStrings } from '../modules/static/_footer/strings';

/**
 * Static pages
 */
import { strings as homeStrings } from '../modules/static/home/strings';
import { strings as softwareDevelopmentStrings } from '../modules/static/software-development/strings';
import { strings as webDesignStrings } from '../modules/static/web-design/strings';
import { strings as aboutStrings } from '../modules/static/about/strings';

const languages = ['en', 'de'];

const injectables = [
	globalStrings,
	footerStrings,

	/* Static pages */
	homeStrings,
	softwareDevelopmentStrings,
	webDesignStrings,
	aboutStrings
];

const _strings = {};

for (const lang of languages) {
	const translationsForLanguage = {};
	for (const injectable of injectables) {
		const translations = injectable[lang];
		for (const i of Object.keys(translations)) {
			translationsForLanguage[i] = translations[i];
		}
	}
	_strings[lang] = translationsForLanguage;
}

export const strings = new LocalizedStrings(_strings);
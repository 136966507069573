import React from 'react';
import PropTypes from 'prop-types';
import DesktopMock from '../../../img/software-engineering/mocks/desktop.png';
import LaptopMock from '../../../img/software-engineering/mocks/laptop.png';
import SmartphoneMock from '../../../img/software-engineering/mocks/smartphone.png';
import TabletMock from '../../../img/software-engineering/mocks/tablet.png';
import './split-banner.scss';

function getHeader(header, headerColor) {
	if (header) {
		const color = headerColor ? headerColor : 'black';
		return <h4 style={{ color: color }}>{ header }</h4>;
	}
	return null;
}

export const SplitBanner = props => {
	return (
		<div className={'split-banner--wrapper'}>
			{ getHeader(props.header, props.headerColor) }
			<div className={'split-banner__inner--wrapper'}>
				<div className={'split-banner__inner split-banner__left--wrapper'}>
					<div className={'split-banner__image--wrapper split-banner__left-image-wrapper'}>
						<img
							className={'split-banner__image'}
							src={DesktopMock}
							alt={'Desktop mockup'}
						/>
					</div>
					<div className={'split-banner__image--wrapper split-banner__left-image-wrapper'}>
						<img
							className={'split-banner__image'}
							src={LaptopMock}
							alt={'Laptop mockup'}
						/>
					</div>
				</div>
				<div className={'split-banner__inner split-banner__right--wrapper'}>
					<div className={'split-banner__image--wrapper split-banner__right-image-wrapper'}>
						<img
							className={'split-banner__image'}
							src={TabletMock}
							alt={'Tablet mockup'}
						/>
					</div>
					<div className={'split-banner__image--wrapper split-banner__right-image-wrapper'}>
						<img
							className={'split-banner__image'}
							src={SmartphoneMock}
							alt={'Smartphone mockup'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

SplitBanner.propTypes = {
	header: PropTypes.string,
	headerColor: PropTypes.string
};
import React, { Component } from 'react';
import { AppRouter } from './app/modules/routing/app-router';
import { config } from './app/core/config/config';
import './App.scss';

document.title = config.baseTitle;

class App extends Component {
    render() {
        return (
            <div className='app'>
                <AppRouter/>
            </div>
        );
    }
}

export default App;

// trigger build

const webDesignDescriptionText = `
As a web developer and web designer I create customized websites and web applications based on latest technologies and
standards and provide support for my customers from conception to release. My services include consulting, designing the
website’s structure and layout, frontend and backend development, CMS configuration or creation and maintenance.
`;

const responsiveBannerText = `
Modern web design knows no display bounds. Whether it’s desktop, tablet or mobile phone: due to the layout being able to
adapt itself to various devices, your content remains accessible from anywhere. Also, different interaction
possibilities have to be considered when developing the website‘s structure and UI elements.
`;

const supportBannerText = `
I take care of your website’s entire development process: consultation on applicable technologies, layout and design,
backend and database design, frontend and layout implementation, configuring the content management system – also
creating customized CMS in the case of special requirements –, search engine optimization, and installing your website
on a production server. Even after project completion I provide comprehensive support and maintenance services.
`;

export const en = {
	/* Core */
	webDesignDescriptionText,
	/* Banners */
	responsiveBannerHeader: 'View your Content on All Devices',
	responsiveBannerText,
	supportBannerHeader: 'Full-Stack-Support – from Layout to Publication',
	supportBannerText
};
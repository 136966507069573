import React from 'react';
import { strings } from '../../../messages/messages';
import { MainBanner } from '../../../shared/components/main-banner/main-banner';
import { titleCase } from '../../../core/utils/helper-functions';
import { TextBlock } from '../../../shared/components/text-block/text-block';
import { Banner } from '../../../shared/components/banner/banner';
import { TextPosition } from '../../../shared/components/banner/style-helpers';
import { BlockQuote } from '../../../shared/components/block-quote/block-quote';
import { ContactButton } from '../../../shared/components/contact-button/contact-button';
import UnsplashImage from '../../../img/web-design/unsplash.jpg';
import planning from '../../../img/software-engineering/planning.jpg';
import ResponsiveImage from '../../../img/web-design/responsive.jpg';
import scssStyles from '../../../../Variables.scss';
import { config } from '../../../core/config/config';
import './web-design.scss';

export class WebDesign extends React.PureComponent {

	componentDidMount() {
		this.props.onPageChange('webDesign');
		this.props.onContributionPush(null);
		window.scrollTo(0, 0);
	}

	render() {
		document.title = config.baseTitle + ' | ' + titleCase(strings.webDesign);
		return (
			<>
				<MainBanner bannerImg={UnsplashImage} title={titleCase(strings.webDesign)} />
				<div style={{ width: '100%', height: '4em' }}/>
				<div className={'wd__block-quote--wrapper'}>
					<BlockQuote
						quote={'Design and programming are human activities; forget that and all is lost.'}
						reference={'Bjarne Stroustrup'}
					/>
				</div>
				<div className={'wd__text-block'}>
					<TextBlock text={strings.webDesignDescriptionText}/>
				</div>
				<div style={{ width: '100%', height: '5em' }}/>
				<Banner
					bgColor={scssStyles['npRed']}
					header={strings.responsiveBannerHeader}
					headerColor={'#ffffff'}
					description={strings.responsiveBannerText}
					descriptionColor={'#ffffff'}
					image={ResponsiveImage}
					textPosition={TextPosition.RIGHT}
				/>
				<div style={{ width: '100%', height: '5em' }}/>
				<Banner
					bgColor={scssStyles['npBlue']}
					header={strings.supportBannerHeader}
					headerColor={'#ffffff'}
					description={strings.supportBannerText}
					descriptionColor={'#ffffff'}
					image={planning}
					textPosition={TextPosition.LEFT}
				/>
				<div className={'wd__contact-button--wrapper'}>
					<ContactButton backgroundColor={scssStyles['npRed']} textColor={'#ffffff'}/>
				</div>
			</>
		);
	}

}
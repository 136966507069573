import React from 'react';
import { strings } from '../../../messages/messages';
import { config } from '../../../core/config/config';
import { titleCase } from '../../../core/utils/helper-functions';
import './legal.scss';

export class Legal extends React.PureComponent {

	componentDidMount() {
		this.props.onPageChange('legal');
		this.props.onContributionPush(null);
		window.scrollTo(0, 0);
	}

	render() {
		document.title = config.baseTitle + ' | ' + titleCase(strings.legal);
		return (
			<div className="limit-width">
				<h1>{ strings.legal }</h1>
				<div id="legal-notice-main">

					<p id="disclosure-requirement">Kleine Offenlegungspflicht gem. § 25 Abs 5 MedienG:</p>

					<p id="name-and-address">
						Nenad Petković<br/>
						Pater-Schwartz-Gasse 11A (Stockwerk Coworking)<br/>
						1150 Wien<br/>
						Österreich
					</p>

					<p id="uid">
						Rechtsform: Einzelunternehmer<br/>
						UID: ATU73367723<br/>
						Gewerbe: Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik, Werbegrafik-Designer<br/>
						Behörde gem. ECG: Magistratisches Bezirksamt des II. Bezirkes<br/>
						Mitglied der Wirtschaftskammer Wien, FG Unternehmensberatung und Informationstechnologie, FG Werbung und Marktkommunikation<br/>
						Anwendbare Rechtsvorschriften: Gewerbeordnung (siehe <a href="https://www.ris.bka.gv.at/">https://www.ris.bka.gv.at/</a>)<br/>
						Sonstige Informationen nach §14 UGB: keine<br/>
						Firmensitz (Ort der Hauptniederlassung): Wien<br/>
						Unternehmensgegenstand: IT-Dienstleistungen, Softwareentwicklung, Webdesign (Werbegrafik-Design)
					</p>

					<p id="contact-data">
						E-Mail: <a href="mailto:root@nenad-petkovic.com">root[at]nenad-petkovic.com</a><br/>
						Tel: <span id="telephone-number">+43 681 81848928</span>
					</p>

					<div id="further-text">
						<h2>Erklärung zur Informationspflicht</h2>

						<h5>(Datenschutzerklärung)</h5>

						<p>
							Der Schutz Ihrer persönlichen Daten ist mir ein besonderes Anliegen. Ich verarbeite Ihre Daten daher
							ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
							Datenschutzinformationen informiere ich Sie über die wichtigsten Aspekte der Datenverarbeitung im
							Rahmen meiner Website.
						</p>

						<h5>Kontakt mit mir</h5>

						<p>
							Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit mir aufnehmen, werden Ihre
							angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei
							mir gespeichert. Diese Daten gebe ich nicht ohne Ihre Einwilligung weiter.
						</p>

						<h5>Ihre Rechte</h5>

						<p>
							Ihnen stehen bezüglich Ihrer bei mir gespeicherten Daten grundsätzlich die Rechte auf Auskunft,
							Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie
							glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
							datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei mir (
							<a href="mailto:root@nenad-petkovic.com">root[at]nenad-petkovic.com</a>) oder der Datenschutzbehörde beschweren.
						</p>

						<h4>Sie erreichen mich unter folgenden Kontaktdaten:</h4>

						<p>
							Postanschrift:<br/>
							Nenad Petkovic<br/>
							Pater-Schwartz-Gasse 11A<br/>
							1150 Wien<br/>
							Österreich
						</p>
						<p>
							E-Mail:<br/>
							<a href="mailto:root@nenad-petkovic.com">root[at]nenad-petkovic[dot]com</a>
						</p>
						<p>
							Telefon:<br/>
							+43 681 81848928
						</p>
					</div>

				</div>
			</div>
		);
	}

}
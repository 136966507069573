const webDesignDescriptionText = `
Ich realisiere maßgeschneiderte Webseiten und webbasierte Applikationen auf Basis modernster Technologien und Standards
und begleite meine Kund*innen von der Konzeption bis zur Veröffentlichung. Meine Dienstleistungen umfassen: Planung und
Beratung, Entwurf der Struktur der Webseite und Layout, Frontend- und Backendentwicklung, Server-Konfiguration,
CMS-Installation und -Konfiguration sowie Wartung und Service.`;

const responsiveBannerText = `
Modernes Webdesign kennt keine statischen Displaygrößen. Ob Desktop-Monitor, Tablet oder Smartphone: Durch die Anpassung
des Layouts auf das jeweils verwendete Endgerät bleibt der Inhalt von überall gleichermaßen zugänglich. Auch auf
unterschiedliche Interaktionsmöglichkeiten – auf einem Smartphone gibt es typischerweise keine Mauszeiger – muss beim
Design von Struktur und Bedienelementen stets Rücksicht genommen werden.
`;

const supportBannerText = `
Ich übernehme den gesamten Entwicklungsprozess deiner Webseite: Konsultation zu einsetzbaren Technologien, Layout und
Design, Backend und Datenbankendesign, Frontend und Layoutimplementierung, Konfiguration des CMS – bei speziellen
Anforderungen auch Schaffung einer maßgeschneiderten CMS-Lösung –, Suchmaschinenoptimierung sowie Installation der Seite
auf dem Produktionsserver. Auch nach Projektschluss stehe ich mit einem umfassenden Betreuungs- und Wartungsangebot zur
Verfügung.
`;

export const de = {
	/* Core */
	webDesignDescriptionText,
	/* Banners */
	responsiveBannerHeader: 'Ob groß oder klein: Responsive Design',
	responsiveBannerText,
	supportBannerHeader: 'Full-Stack-Betreuung – vom Layout bis zur Veröffentlichung',
	supportBannerText
};
import React from 'react';
import MediaQuery from 'react-responsive';
import './header.scss';
import { Link } from 'react-router-dom';
import { config } from '../../../core/config/config';
import { strings } from '../../../messages/messages';

export const Header = ({ selected }) => {
	return (
		<nav id="navbar" className="limit-width">
			<h1 id="header">:: nenad petković</h1>
			<div id="navbar-right">
				<MediaQuery maxWidth={config.screenSizes.s}>
					<div id='brace-open'>
						{ '{ ' }
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/software-development'}>
							{ selected === 'softwareDevelopment' ? '<' : '' }
							{ strings.softwareDevelopment.toUpperCase() }
							{ selected === 'softwareDevelopment' ? '>' : '' },
						</Link>
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/web-design'}>
							{ selected === 'webDesign' ? '<' : '' }
							{ strings.webDesign.toUpperCase() }
							{ selected === 'webDesign' ? '>' : '' },
						</Link>
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/about'}>
							{ selected === 'about' ? '<' : '' }
							{ strings.about.toUpperCase() }
							{ selected === 'about' ? '>' : '' }
						</Link>
					</div>
					<div id='brace-close'>
						{ ' }' }
					</div>
				</MediaQuery>
				<MediaQuery minWidth={config.screenSizes.s}>
					<div id='brace-open'>
						{ '{ ' }
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/software-development'}>
							{ selected === 'softwareDevelopment' ? '<' : '' }
							{ strings.softwareDevelopment.toUpperCase() }
							{ selected === 'softwareDevelopment' ? '>' : '' },
						</Link>
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/web-design'}>
							{ selected === 'webDesign' ? '<' : '' }
							{ strings.webDesign.toUpperCase() }
							{ selected === 'webDesign' ? '>' : '' },
						</Link>
					</div>
					<div className={'navbar-right__element'}>
						<Link to={'/about'}>
							{ selected === 'about' ? '<' : '' }
							{ strings.about.toUpperCase() }
							{ selected === 'about' ? '>' : '' }
						</Link>
					</div>
					<div id='brace-close'>
						{ ' }' }
					</div>
				</MediaQuery>
			</div>
		</nav>
	);
};
export const TextPosition = {
	LEFT: 'LEFT',
	RIGHT: 'RIGHT'
};
Object.freeze(TextPosition);

export function getBannerBgColor(bgColor) {
	return ({
		backgroundColor: bgColor
	});
}

export function getOrderForPosition(textPosition, screenSize, isText) {
	switch (screenSize) {
		case 'max-xs':
		case 'min-xs':
		case 's':
		case 'm':
			if (isText) {
				return ({ order: 2 });
			}
			return ({ order: 1 });
		case 'l':
		case 'xl':
		default:
			if (textPosition === TextPosition.LEFT) {
				if (isText) {
					return ({order: 1});
				}
				return ({ order: 2 });
			} else {
				if (isText) {
					return ({ order: 2 });
				}
				return ({ order: 1 });
			}
	}
}

export function getPaddingForPosition(textPosition, screenSize, isText) {
	switch (screenSize) {
		case 'max-xs':
		case 'min-xs':
		case 's':
		case 'm':
			if (isText) {
				return ({
					paddingLeft: textPosition === TextPosition.LEFT ? '6vw' : '6vw',
					paddingRight: textPosition === TextPosition.LEFT ? '6vw' : '6vw'
				});
			}
			return ({
				paddingLeft: textPosition === TextPosition.LEFT ? '0' : '0',
				paddingRight: textPosition === TextPosition.LEFT ? '0' : '0'
			});
		case 'l':
			if (isText) {
				return ({
					paddingLeft: textPosition === TextPosition.LEFT ? '0' : '4vw',
					paddingRight: textPosition === TextPosition.LEFT ? '4vw' : '0'
				});
			}
			return ({
				paddingLeft: textPosition === TextPosition.LEFT ? '4vw' : '0',
				paddingRight: textPosition === TextPosition.LEFT ? '0' : '4vw'
			});
		default:
			if (isText) {
				return ({
					paddingLeft: textPosition === TextPosition.LEFT ? '0' : '4vw',
					paddingRight: textPosition === TextPosition.LEFT ? '4vw' : '0'
				});
			}
			return ({
				paddingLeft: textPosition === TextPosition.LEFT ? '4vw' : '0',
				paddingRight: textPosition === TextPosition.LEFT ? '0' : '4vw'
			});
	}
}
import React from 'react';
import PropTypes from 'prop-types';
import './banner.scss';
import { config } from '../../../core/config/config';
import MediaQuery from 'react-responsive';
import {
	TextPosition,
	getBannerBgColor,
	getOrderForPosition,
	getPaddingForPosition
} from './style-helpers';

function getInnerJsx(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt, screenSize) {
	return (
		<div className={'banner'} style={getBannerBgColor(bgColor)}>
			<div className={'banner--content'}>
				<div className={'banner__text--wrapper'} style={getOrderForPosition(textPosition, screenSize, true)}>
					<div
						className={'banner__header--wrapper'}
						style={getPaddingForPosition(textPosition, screenSize, true)}
					>
						<h2 className={'banner__header'} style={{ color: headerColor }}>{ header }</h2>
					</div>
					<div
						className={'banner__description--wrapper'}
						style={getPaddingForPosition(textPosition, screenSize, true)}
					>
						<p className={'banner__description'} style={{ color: descriptionColor }}>{ description }</p>
					</div>
				</div>
				<div
					className={'banner__image--wrapper'}
					style={{
						...getOrderForPosition(textPosition, screenSize, false),
						...getPaddingForPosition(textPosition, screenSize, false)
					}}
				>
					<img className={'banner__image'} src={image} alt={imageAlt}/>
				</div>
			</div>
		</div>
	);
}

function getResponsiveContent(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt) {
	return (
		<>
			<MediaQuery maxWidth={config.screenSizes.xs}>
				{ getInnerJsx(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt, 'max-xs') }
			</MediaQuery>
			<MediaQuery minWidth={config.screenSizes.xs + 1} maxWidth={config.screenSizes.m}>
				{ getInnerJsx(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt, 'min-xs') }
			</MediaQuery>
			<MediaQuery minWidth={config.screenSizes.m + 1} maxWidth={config.screenSizes.l}>
				{ getInnerJsx(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt, 'm') }
			</MediaQuery>
			<MediaQuery minWidth={config.screenSizes.l + 1}>
				{ getInnerJsx(bgColor, textPosition, header, headerColor, description, descriptionColor, image, imageAlt, 'l') }
			</MediaQuery>
		</>
	);
}

export const Banner = props => {
	const bgColor = props.bgColor ? props.bgColor : '#ffffff';
	return getResponsiveContent(
		bgColor,
		props.textPosition,
		props.header,
		props.headerColor,
		props.description,
		props.descriptionColor,
		props.image,
		props.imageAlt
	);
};

Banner.propTypes = {
	bgColor: PropTypes.string,
	header: PropTypes.string.isRequired,
	headerColor: PropTypes.string,
	description: PropTypes.string.isRequired,
	descriptionColor: PropTypes.string,
	image: PropTypes.string,    // image url
	imageAlt: PropTypes.string,
	textPosition: PropTypes.oneOfType([PropTypes.node])
};

Banner.defaultProps = {
	imageAlt: 'This is a cat picture because this website‘s author didn’t bother to add a real banner image.',
	textPosition: TextPosition.LEFT,
	headerColor: '#000000',
	descriptionColor: '#000000'
};